<template>
  <div class="container">
    <van-nav-bar
    title="商品列表"
    left-arrow
    fixed
    @click-left="onClickLeft"
    />
    <div style="height:46px;"></div>
    <div class="tips">*点击选择您想要的一款商品*</div>
    <div class="goodslist">
        <div class="goodsitem" v-for="(item,index) in listData" :key="index" @click="goTodetal(item.goods_title,item.id)"> 
            <div class="item-txt">{{item.goods_txt}}</div>
            <div class="acea-row row-between-wrapper"><div class="item-title">{{item.goods_title}}</div><div class="item-sale">销量:{{item.sale+item.no_sale}}单</div></div>
            <div class="item-title txt">第一年提货日期：{{item.begin}}至{{item.end}}</div>
            <div class="acea-row row-middle">
                <img :src="item.goods_img" alt="" class="goodsimg" />
                <div class="item-info">
                    <div v-for="(attr,ind) in item.attrs" :key="ind">{{attr.name}}</div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {productList} from '@/api/hexiao' 
export default {
    data() {
        return {
           type:this.$route.query.type || 1,
           listData:[]
        };
    },
    created(){
      this.getProductList()
    },
    methods:{
        onClickLeft(){
            this.$router.go(-1)
        },
        getProductList(){
            productList(this.type).then(res=>{
                this.listData = res.data
            })
        },
        goTodetal(title,id){
            localStorage.setItem('goodstitle',title);
            localStorage.setItem('goodsId',id);
            this.$router.go(-1)
        }
    }
}
</script>

<style lang="less" scoped>
.tips{
    width: 100%;
    position: fixed ;
    color: #fff;
    font-size: 14px;
    text-align: center;
    border: 1px solid #f80;
    line-height: 25px;
    background: red;
    z-index: 1;
}
.goodslist{
    padding:5px 5px 10px;
    margin-top: 25px;
    .goodsitem{
        margin-top: 15px;
        background: #fff;
        box-shadow: 2px 2px 17px -6px rgba(171,109,109,0.5);
        border-radius: 6px;
        padding: 10px 1px;
        min-height: 250px;
        position: relative;
        .item-txt{
            width: 210px;
            height: 25px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 600;
            color: #000000;
            line-height: 25px;
            background: linear-gradient(90deg,#E6CF93,#FEEFCD);
            border-radius: 1px;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            text-align: center;
        }
        .item-title{
            margin: 5px 10px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #000000;
            line-height: 20px;
        }
        .item-sale{
            margin: 5px 20px 5px 5px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 500;
            color: #666;
            line-height: 20px;
        }
        .txt{
            color:red;
            font-size: 16px;
        }
        .cart{
            position: absolute;
            width: 65px;
            height: 45px;
            top:5px;
            right: 5px;
        }
        .goodsimg{
            width: 146px;
            height: 146px;
            margin-right: 8px;
            border-radius: 4px;
            margin-left: 3px;
        }
        .item-info{
            flex:1;
            font-size: 12px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 450;
            color: #666;
            line-height: 16px;

        }
    }
}
</style>